import axios from "axios";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ScrollToTop from "./components/ScrollToTop/ScrollTotop";
import ScrollToTopButton from "./components/ScrollToTop/ScrollToTopButton";
import Accueil from "./screens/Accueil/Accueil";
import Administration from "./screens/Administration/Administration";
import { CandidatureForm } from "./screens/CandidatureForm/CandidatureForm";
import Cgu from "./screens/Cgu/Cgu";
import Connexion from "./screens/Connexion/Connexion";
import Contact from "./screens/Contact/Contact";
import { NousRejoindre } from "./screens/NousRejoindre/NousRejoindre";
import { OffreEmploiDetail } from "./screens/OffreEmploiDetail/OffreEmploiDetail";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import Projets from "./screens/Projets/Projets";
import { Propos } from "./screens/Propos/Propos";

import "./App.css";

function App() {
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const oldRoutes = {
    "/accueil": "/",
    "/collaborateurs": "/a-propos",
    "/nos-valeurs": "/a-propos",
    "/nos-partenaires": "/a-propos",
    "/domisimpli": "/projets",
    "/services": "/projets",
    "/offres-emploi": "/rejoindre",
    "/candidature": "/rejoindre",
  };

  return (
    <Router>
      <ScrollToTop />
      <main>
        <Header />
        {userInfo ? (
          <Routes>
            {/* Routes Administrateur */}
            <Route path="/" element={<Accueil />} />
            <Route path="/a-propos" element={<Propos />} />
            <Route path="/projets" element={<Projets />} />
            <Route path="/rejoindre" element={<NousRejoindre />} />
            <Route path="/candidature" element={<CandidatureForm />} />
            <Route path="/offres-detail" element={<OffreEmploiDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cgu" element={<Cgu />} />
            <Route path="/administration" element={<Administration />} />
            <Route path="*" element={<PageNotFound />} />

            {Object.keys(oldRoutes).map((oldPath) => (
              <Route
                key={oldPath}
                path={oldPath}
                element={<Navigate to={oldRoutes[oldPath]} replace />}
              />
            ))}

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        ) : (
          <Container className="children">
            {" "}
            {/* Dans SideBar */}
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/a-propos" element={<Propos />} />
              <Route path="/projets" element={<Projets />} />
              <Route path="/rejoindre" element={<NousRejoindre />} />
              <Route path="/candidature" element={<CandidatureForm />} />
              <Route path="/offres-detail" element={<OffreEmploiDetail />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/connexion" element={<Connexion />} />
              <Route path="/cgu" element={<Cgu />} />

              {Object.keys(oldRoutes).map((oldPath) => (
                <Route
                  key={oldPath}
                  path={oldPath}
                  element={<Navigate to={oldRoutes[oldPath]} replace />}
                />
              ))}

              {/* Routes Visiteur */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Container>
        )}

        <Footer />
        <ScrollToTopButton />
      </main>
    </Router>
  );
}

export default App;
