import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "../../components/Button/LinkButton/LinkButton";
import JobCard from "../../components/JobCard/JobCard";
import { listJobOffers } from "../../redux/actions/jobOfferActions";
import "./style.css";

export const NousRejoindre = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobOfferList = useSelector((state) => state.jobOfferList);
  const { jobOffers, loading, error } = jobOfferList;

  useEffect(() => {
    dispatch(listJobOffers());
  }, [dispatch]);

  const handleApplyClick = () => {
    navigate("/candidature");
  };

  const checkIfJobOffer =
    jobOffers && Array.isArray(jobOffers) && jobOffers.length > 0;

  return (
    <div className="nous-rejoindre">
      <Helmet>
        <title>Nous Rejoindre</title>
        <meta name="description" content="Nous Rejoindre" />
        <meta
          name="keywords"
          content="équipe, coode-nt, rejoindre, offres d'emploi, candidature, avenir"
        />
      </Helmet>
      <div className="content-section">
        <div className="auto-layout-vertical">
          <div className="auto-layout-vertical-2">
            <div className="text-wrapper-5">Nous Rejoindre</div>
            <div className="text-wrapper-6">
              Vous souhaitez rejoindre une équipe dynamique et passionnée ?
              Consultez nos offres d’emploi ou envoyez-nous votre candidature
              spontanée. Chaque candidature reçoit toute notre attention, et
              nous nous engageons à vous répondre. Ensemble, construisons
              l’avenir !
            </div>
          </div>
          <div className="separation-line margin-zero" />
          <div
            className={`job-offers-grid ${!checkIfJobOffer ? "no-grid" : ""}`}
          >
            {loading ? (
              <p>Chargement des membres projets...</p>
            ) : error ? (
              <p>
                Aucune offre trouvée{" "}
                {console.log(
                  "Erreur lors de la récupération des offres : ",
                  error
                )}
              </p>
            ) : checkIfJobOffer ? (
              jobOffers.map((job, index) => (
                <JobCard
                  key={index}
                  title={job.title}
                  position={job.position}
                  location={job.location}
                  skills={job.skills}
                  skills_short={job.skills_short}
                  extra_skills={job.extra_skills}
                  missions={job.missions}
                  why_join={job.why_join}
                  benefits_join={job.benefits_join}
                  id={job.id}
                />
              ))
            ) : (
              <p className="no-offers">
                De nouvelles offres seront publiées prochainement, restez à
                l'écoute !
              </p>
            )}
          </div>
        </div>
        <span className="pointer-span" onClick={handleApplyClick}>
          <LinkButton
            className="design-component-instance-node-2"
            style="filled"
            text="Candidature spontanée"
          />
        </span>
      </div>
    </div>
  );
};
