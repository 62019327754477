import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { listJobOffers, deleteJobOffer } from "../../../../../redux/actions/jobOfferActions";
import { candidatureEmploiListAction } from "../../../../../redux/actions/candidatureEmploiActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const OffreTable = ({ handleEditOffer, handleWaitingCandidate, handleCandidateClick }) => {
    const dispatch = useDispatch();

    const jobOfferList = useSelector((state) => state.jobOfferList);
    const { jobOffers, loading, error } = jobOfferList;

    const candidatureEmploiList = useSelector((state) => state.candidatureEmploiList);
    const { candidatures } = candidatureEmploiList;

    useEffect(() => {
        dispatch(listJobOffers());
        dispatch(candidatureEmploiListAction());
    }, [dispatch]);

    const handleDelete = (id) => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette offre ?");
        if (isConfirmed) {
            dispatch(deleteJobOffer(id)).then(() => dispatch(listJobOffers()));
        }
    };

    return (
        <div className="job-offer-table">
            {loading ? <p>Chargement des offres...</p> : error ? <p>{error}</p> : (
                <table>
                    <thead>
                        <tr>
                            <th>notif</th>
                            <th>Type de contrat</th>
                            <th>Poste</th>
                            <th>Localisation</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobOffers.map((offer) => (
                            <tr key={offer.id}>
                                <td className="candidature-count">
                                    {handleWaitingCandidate(offer.id) ? (
                                        <span className="candidature-count-number pointer-span" onClick={() => handleCandidateClick(offer.id)}>
                                            {handleWaitingCandidate(offer.id)}
                                        </span>

                                    ) : 0}
                                </td>
                                <td>{offer.title}</td>
                                <td>{offer.position}</td>
                                <td>{offer.location}</td>
                                <td className="button-td">
                                    <button onClick={() => handleEditOffer(offer)}>
                                        <FontAwesomeIcon icon={faEdit} /> Modifier
                                    </button>
                                    <button className='del-button' onClick={() => handleDelete(offer.id)}>
                                        <FontAwesomeIcon icon={faTrash} /> Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default OffreTable;
