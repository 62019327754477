import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { PlaceholderImage3 } from "../../icons/PlaceholderPropos/PlaceholderImage3"; // section videos
import { Helmet } from "react-helmet";
import TeamMember from "../../components/TeamMember/TeamMember.js";
import { teamMemberListAction } from "../../redux/actions/teamMembersActions";
import { listVideos } from "../../redux/actions/videoActions";
import "./Propos.css";
import "./video.css";

import image2 from "../../assets/images/a_propos_2.png";
import image3 from "../../assets/images/a_propos_3.png";
import image4 from "../../assets/images/a_propos_4.png";
import image5 from "../../assets/images/a_propos_5.png";
import image1 from "../../assets/images/coode-nt-work.png";
import frame1 from "../../assets/images/Frame 11.png";

import { BigPlayButton, Player } from "video-react";
import poster2 from "../../assets/images/interview-franck-coodeNT.png";

export const Propos = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  // Utilisation de Redux pour récupérer les membres de l'équipe
  const teamMemberList = useSelector((state) => state.teamMemberList);
  const { teamMembers, loading, error } = teamMemberList;

  const videoList = useSelector((state) => state.videoList);
  const { videos } = videoList;

  // Filtrage pour n'afficher que les membres actifs
  const activeTeamMembers =
    teamMembers && teamMembers.filter((member) => member.is_active);

  // Détecte si on est en version mobile ou desktop en fonction de la largeur de la fenêtre
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Définir 768px comme seuil mobile
    };

    // Ecoute la taille de la fenêtre dès le montage
    handleResize();
    window.addEventListener("resize", handleResize);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // Dispatch de l'action pour obtenir les membres de l'équipe
    dispatch(teamMemberListAction());
    dispatch(listVideos());
  }, [dispatch]);

  return (
    <div className="propos">
      <Helmet>
        <title>À Propos</title>
        <meta name="description" content="À Propos" />
        <meta
          name="keywords"
          content="valeurs, COODE-NT , écoute, innovation, développer, solutions, talents, partage, collaboration, projets, tendances, objectif, participatif, équipe"
        />
      </Helmet>
      {/* Section Découvrez COODE-NT */}
      <div className="auto-layout-2">
        {/* {isMobile ? (
          <img src='https://img.freepik.com/photos-gratuite/jetee-au-bord-lac-hallstatt-autriche_181624-44201.jpg?ga=GA1.1.132570296.1725958073&semt=ais_hybrid-rr-similar'
            alt='paysage'
            className='discov-img'
          />
        ) : (
          <PlaceholderImage1 className="icon-instance-node" />
        )} */}

        <img
          style={{ display: "none" }}
          className="icon-instance-node"
          alt="Les membres de l'équipe COODE-NT posant réunie, dans un environnement convivial, reflétant l'esprit d'innovation et d'écoute"
          src={image1}
        />
        <div
          className="icon-instance-node"
          style={{ width: 581, height: 261, position: "relative" }}
        >
          <Player
            alt="Les membres de l'équipe COODE-NT posant réunie, dans un environnement convivial, reflétant l'esprit d'innovation et d'écoute"
            src={
              videos &&
              videos !== undefined &&
              videos.length > 0 &&
              videos.filter((v) => v.name === "presentation")[0].url
            }
            poster={image1}
            width="581"
            height="261"
            controls
            controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
          >
            <BigPlayButton position="center" />
          </Player>
        </div>
        <div className="auto-layout-vertical">
          <div className="auto-layout-vertical-2">
            <h1 className="text-wrapper-5">Découvrez COODE-NT </h1>
          </div>
          <p className="text-wrapper-6-coodent">
            COODE-NT se distingue par ses valeurs de partage, d'écoute et
            d'innovation. Nous collaborons étroitement avec vous pour développer
            des solutions sur mesure, en prenant soin de comprendre vos besoins
            et en partageant nos expertises. Chaque projet est guidé par
            l’innovation et un accompagnement personnalisé, garantissant des
            résultats alignés avec vos attentes et les tendances du marché. Chez
            COODE-NT , nous valorisons les talents et l’originalité, pour
            repousser ensemble les limites du possible.
          </p>
        </div>
      </div>

      {/* Section Nos Valeurs */}
      <div className="content-section">
        <div className="text-wrapper-7">Nos valeurs</div>

        {/* Valeur 1: Le Partage */}
        <div className="auto-layout-3 partage">
          {/* <PlaceholderImage3 className="icon-instance-node" /> */}
          <img
            className="icon-instance-node"
            alt="Membres de l'équipe participant à une réunion de travail collaborative, échangeant des idées"
            src={image2}
          ></img>
          <div className="auto-layout-vertical">
            <div className="text-wrapper-8">Le partage</div>
            <p className="text-wrapper-6">
              Le partage est au cœur de notre démarche. Nous travaillons en
              étroite collaboration avec nos clients, partageant nos
              connaissances et idées pour enrichir chaque projet. Grâce à une
              transparence totale, nous développons des solutions innovantes,
              alignées avec les tendances du marché et les besoins des
              utilisateurs finaux.
            </p>
          </div>
        </div>

        {/* Valeur 2: L’Écoute */}
        <div className="auto-layout-3 ecoute">
          <div className="auto-layout-vertical">
            <div className="text-wrapper-8">L’écoute</div>
            <p className="text-wrapper-6">
              L’écoute est essentielle. Nous prenons le temps de comprendre en
              profondeur vos besoins et attentes avant de commencer la
              conception. Cette approche nous permet de créer des solutions sur
              mesure parfaitement alignées avec les objectifs de votre
              entreprise.
            </p>
          </div>
          {/* <PlaceholderImage3 className="icon-instance-node-2" /> */}
          <img
            className="icon-instance-node-2"
            alt="Équipe COODE-NT en pleine réunion, échangeant et écoutant attentivement pour comprendre les besoins du client"
            src={image3}
          ></img>
        </div>

        {/* Valeur 3: Encouragement des Talents */}
        <div className="auto-layout-3 encouragement">
          {/* <PlaceholderImage4 className="icon-instance-node" /> */}
          <img
            className="icon-instance-node"
            alt="Scène de travail chez COODE-NT montrant la conception d'une interface utilisateur sur un écran, symbolisant l'innovation et la créativité"
            src={image4}
          ></img>
          <div className="auto-layout-vertical encouragement">
            <p className="title-wrapper-8">
              Encouragement des talents et management participatif
            </p>
            <p className="text-wrapper-6">
              Nous valorisons l’innovation et le talent à travers une culture de
              management participatif. Chaque membre de l’équipe est encouragé à
              proposer des idées audacieuses et à contribuer activement,
              garantissant des solutions à la fois techniquement avancées et
              créatives.
            </p>
          </div>
        </div>

        {/* Valeur 4: L’Accompagnement */}
        <div className="auto-layout-3 accompagnement">
          <div className="auto-layout-vertical">
            <div className="text-wrapper-8">L’accompagnement</div>
            <p className="text-wrapper-6">
              L’accompagnement va au-delà de l’assistance technique. Nous vous
              soutenons à chaque étape, de la conception à l’évolution de votre
              application, en vous offrant conseils et guidance pour maximiser
              votre succès. Notre objectif est de vous fournir une solution
              exceptionnelle tout en vous permettant de tirer pleinement parti
              de chaque opportunité.
            </p>
          </div>
          {/* <PlaceholderImage3 className="icon-instance-node-2" /> */}
          <img
            className="icon-instance-node-2"
            alt="Collaboration entre membres de l'équipe COODE-NT , mettant en avant l'engagement à fournir des solutions techniques et conseils personnalisés"
            src={image5}
          ></img>
        </div>

        {/* Section Vidéo */}
        <div className="auto-layout-3 video2">
          <div
            className="icon-instance-node"
            style={{ width: 540, height: 213, position: "relative" }}
          >
            <Player
              alt="Interview Franck COODE-NT "
              src={
                videos &&
                videos !== undefined &&
                videos.length > 0 &&
                videos.filter((v) => v.name === "interview_franck")[0].url
              }
              width="540"
              height="213"
              poster={poster2}
              controls
              controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
            >
              <BigPlayButton position="center" />
            </Player>
          </div>

          <div className="auto-layout-vertical">
            <div className="text-wrapper-8">
              L’entreprise née d’un coude brisé, mais avec des idées bien
              droites
            </div>
            <p className="text-wrapper-6">
              Dans cette interview, notre PDG nous raconte l’histoire étonnante
              de la création de COODE-NT : comment un accident de parcours (un
              coude cassé, pour être précis) a donné naissance à une vision, un
              projet, et finalement à une entreprise à part entière. Entre
              anecdotes, défis et rebonds (littéralement !), il partage avec
              nous son parcours, les valeurs qui ont guidé le développement de
              COODE-NT et sa vision audacieuse pour l’avenir. Une histoire de
              persévérance et de créativité qui montre qu’il suffit parfois d’un
              petit accident pour démarrer une grande aventure.
            </p>
          </div>
        </div>
      </div>

      {/* Section Notre Équipe */}
      <div className="content-section-3">
        <div className="auto-layout-vertical-4">
          <div className="text-wrapper-9">Notre équipe</div>
          <p className="p">
            Chaque membre apporte une expertise unique et une passion pour
            l’innovation, contribuant ainsi à la réussite de nos projets.
            Découvrez bientôt les talents qui façonnent nos solutions et
            découvrons ensemble comment leur expertise peut transformer vos
            idées en réalité. Restez connectés pour en savoir plus sur ceux qui
            rendent tout cela possible !
          </p>
        </div>
        <div className="auto-layout-4">
          {loading ? (
            <p>Chargement des membres de l'équipe...</p>
          ) : error ? (
            <p>
              {console.log(
                "Erreur lors de la récupération des membres : ",
                error
              )}
            </p>
          ) : isMobile ? (
            activeTeamMembers.map((membre, index) => (
              <div key={index}>
                <TeamMember
                  name={membre.name}
                  title={membre.title}
                  image={membre.image_url}
                />
              </div>
            ))
          ) : (
            activeTeamMembers &&
            activeTeamMembers.length > 0 &&
            activeTeamMembers.map((membre, index) => (
              <TeamMember
                key={index}
                name={membre.name}
                title={membre.title}
                image={membre.image_url}
              />
            ))
          )}
          {/* Carte cliquable pour rejoindre l'équipe */}
          <a href="/rejoindre" className="join-card">
            <img className="join-card-content" src={frame1} alt=""></img>
          </a>
        </div>
      </div>
    </div>
  );
};
