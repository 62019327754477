// src/components/TeamMember.js
import React from "react";
import "../../screens/Propos/Propos.css";

const TeamMember = ({ name, title, image }) => {
  // URL de l'image par défaut pour desktop et mobile
  const defaultImage = "https://via.placeholder.com/246x296";
  const defaultImage2 = "https://via.placeholder.com/134x158";

  // Déterminer la taille de l'écran
  const isMobile = window.innerWidth <= 375;

  // Styles conditionnels pour l'image
  const imageStyle = {
    width: isMobile ? "134px" : "246px",
    height: isMobile ? "158px" : "296px",
  };

  // Styles conditionnels pour les textes
  const nameStyle = {
    fontSize: isMobile ? "20px" : "18px",
    lineHeight: isMobile ? "24px" : "32px",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: isMobile ? "0.875rem" : "1.25rem",
    lineHeight: isMobile ? "1.2" : "1.5",
    textAlign: "center",
  };

  return (
    <div className="frame-3">
      <img
        src={
          image
            ? `data:image/png;base64,${image}`
            : isMobile
            ? defaultImage2
            : defaultImage
        }
        alt={name}
        className="imag-2"
      />
      <div className="text-wrapper-10" style={nameStyle}>
        {name}
      </div>
      <div className="text-wrapper-12" style={titleStyle}>
        {title}
      </div>
    </div>
  );
};

export default TeamMember;
