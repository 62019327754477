import React from "react";

import { Helmet } from "react-helmet";
import votreProjet from "../../assets/images/votreProjet.png";
import { LinkButton } from "../../components/Button/LinkButton/LinkButton";
import ProjetSection from "../../components/Projet/ProjetSection";
import "./Projets.css";

function Projets() {
  return (
    <div className="nos-projets">
      <Helmet>
        <title>Nos Projets</title>
        <meta name="description" content="Nos projets" />
        <meta
          name="keywords"
          content="projets, coode-nt, solution innovante, Domisimpli, être averti, sur mesure, développer, nous contacter"
        />
      </Helmet>
      <div className="content-section">
        <h1 className="text-wrapper-5">Nos Projets</h1>
        <ProjetSection />
      </div>
      <div className="CTA">
        <img
          className="placeholder-image"
          alt="Mains tapant sur un clavier d'ordinateur, représentant le début d'une collaboration pour un projet sur mesure"
          src={votreProjet}
        ></img>
        <div className="auto-layout-vertical-3">
          <div className="auto-layout-vertical-4">
            <p className="text-wrapper-7">Envie de lancer votre projet ?</p>
            <p className="p">
              Nous croyons que chaque projet mérite une approche sur mesure.{" "}
              <br />
              Contactez-nous pour développer des solutions adaptées à vos
              utilisateurs. Que vous soyez en phase d’idée ou déjà avancé, notre
              équipe vous accompagne à chaque étape, avec un focus sur
              l’expérience utilisateur et l’efficacité. <br />
              Donnons vie à vos ambitions ensemble. Contactez-nous dès
              maintenant !
            </p>
          </div>
          <LinkButton
            className="design-component-instance-node-2"
            style="filled"
            text="Nous contacter"
            to={`/contact`}
          />
        </div>
      </div>
    </div>
  );
}

export default Projets;
