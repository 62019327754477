// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/coode-nt-white-1.png';
import './Footer.css';
import linkedin from '../../assets/linkedin logo.svg';
import { LinkButton } from "../Button/LinkButton/LinkButton";

function Footer() {

  const urlLinkedin = 'https://fr.linkedin.com/company/coode-nt';

  return (
    <footer>
      <div className={`frame design-component-instance-node-2`}>
        <div className="div-2">
          <div className="div-3">
            <img className="coode-nt-white" alt="Coode nt white" src={logo} />
            <p className="text-wrapper-2">Une idée de projet ?</p>
            <LinkButton
              className="design-component-instance-node"
              divClassName="button-2"
              style="outlined"
              text="Contactez-nous"
              to="/contact"
            />
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">Menu</div>
            <Link className="text-wrapper-4" to="/">
              Accueil
            </Link>
            <Link className="text-wrapper-4" to="/a-propos">
              À Propos
            </Link>
            <Link className="text-wrapper-4" to="/projets">
              Nos Projets
            </Link>
            <Link className="text-wrapper-4" to="/rejoindre">
              Nous Rejoindre
            </Link>
          </div>
          <div className="div-4">
            <Link className="text-wrapper-3" to="/contact">
              Contact
            </Link>
            <a href="mailto:contact@coode-nt.com" className="text-wrapper-4">contact@coode-nt.com</a>
            <a href="tel:0251387415" className="text-wrapper-2" >Tél : 02 51 38 74 15</a>
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">Réseaux Sociaux</div>
            <a href={urlLinkedin} target="_blank" rel="noopener noreferrer">
              <img className="linkedin-logo" alt="linkedin-logo" src={linkedin} />
            </a>
          </div>
        </div>
        <p className="text-wrapper-2">© 2024 COODE-NT. Tous droits réservés.</p>
        <div className="text-wrapper-5">
          <Link to="/cgu">CGU</Link>
          {/* <Link to="/pdc">Politique de Confidentialité</Link> */}
        </div>

      </div>
    </footer>
  );
}

export default Footer;
