import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import "./Accueil.css";

import { useDispatch, useSelector } from "react-redux";
import { LinkButton } from "../../components/Button/LinkButton/LinkButton";
import { listParametres } from "../../redux/actions/parametresActions";

import accueil_1 from "../../assets/images/accueil_1.png";
import accueil_2 from "../../assets/images/accueil_2.png";

function Accueil() {
  const dispatch = useDispatch();
  const parametresList = useSelector((state) => {
    return state.parametresList;
  });
  const { parametres, loading, error } = parametresList;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    dispatch(listParametres());
  }, [dispatch]);

  const accueilParam =
    Array.isArray(parametres) && parametres.length
      ? parametres.find((param) => param.nom === "Accueil Card")
      : {};

  return (
    <div className="accueil">
      <Helmet>
        <title>Découvrez nos projets COODE-NT et nos services</title>
        <meta name="description" content="Accueil" />
        <meta
          name="keywords"
          content="services, coode-nt, projets, développement d'applications, web, mobiles"
        />
      </Helmet>
      <div className="auto-layout-2">
        <div className="div-5">
          <div className="auto-layout-vertical">
            <h1 className="text-wrapper-5">
              Découvrez {isMobile && <br />} nos projets
            </h1>
          </div>
          <p className="p">
            Nos projets innovants repoussent les limites du possible et nous
            sommes impatients de vous les faire découvrir. Ne manquez pas nos
            prochains lancements exclusifs en vous inscrivant à notre newsletter
            ! Restez à l’affût des nouveautés, des coulisses de nos réalisations
            et des événements à venir.
          </p>
          <LinkButton
            className="design-component-instance-node-2"
            style="outlined"
            text="Découvrir"
            to="/projets"
          />
        </div>
        <img
          className="placeholder-image accueil_1"
          alt="Aperçu de l'application mobile DOMISIMPLI avec ses différentes fonctionnalités et interfaces utilisateur"
          src={accueil_1}
        ></img>
      </div>
      <div className="content-section">
        <div className="title">
          <div className="text-wrapper-6">Nos services</div>
        </div>
        <p className="text-wrapper-7">
          Chez COODE-NT, nous nous spécialisons dans le développement
          d’applications web et mobiles
        </p>
        <div className="content">
          <div className="div-5">
            <p className="p">
              À la fois pour nos propres besoins et pour ceux de clients
              externes. Nous offrons également un accompagnement global sur
              l&#39;aspect logiciel pour les clients professionnels, que ce soit
              à travers des contrats cadres ou des missions ponctuelles.
            </p>
            <p className="p">
              Notre expertise chez COODE-NT nous permet de répondre efficacement
              à toutes vos demandes. Que ce soit pour des besoins techniques,
              des conseils sur le design, l&#39;ergonomie ou la gestion
              simplifiée de votre réseau, nous sommes là pour vous aider. Faire
              appel à COODE-NT, c&#39;est choisir la garantie d&#39;avoir des
              équipes compétentes et spécialisées dédiées à la réussite de votre
              projet.
            </p>
            <p className="p">
              Nous considérons chaque client comme unique et nous nous engageons
              à vous fournir toute notre attention. L&#39;humain est au cœur de
              notre démarche : comprendre vos besoins, vous accompagner, être
              disponible et performant sont nos engagements pour votre
              satisfaction.
            </p>
          </div>
          <img
            className="placeholder-image"
            alt="Équipe de Coode-NT au travail sur le développement d'applications web et mobiles"
            src={accueil_2}
          ></img>
        </div>
        <div className="style-outlined-wrapper-contact">
          <LinkButton
            className="design-component-instance-node-2"
            style="filled"
            text="Nous contacter"
            to="/contact"
          />
        </div>
      </div>
      {accueilParam && accueilParam.valeur === "True" && (
        <div className="social-proof">
          <div className="auto-layout-3">
            <div className="auto-layout-vertical-2">
              <div className="text-wrapper-5">
                <span className="span">+</span>3
              </div>
              <div className="text-wrapper-8">
                Projets en cours de développement
              </div>
            </div>
            <div className="auto-layout-vertical-2">
              <div className="text-wrapper-5">3M</div>
              <div className="text-wrapper-8">Utilisateurs potentiels</div>
            </div>
            <div className="auto-layout-vertical-2">
              <div className="element">
                <span className="span">+</span>
                <span className="text-wrapper-9">100%</span>
              </div>
              <div className="text-wrapper-8">D'engagement</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Accueil;
