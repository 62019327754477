import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './ScrollToTopButton.css'; // Tu peux ajouter du style ici


const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // Fonction pour faire défiler jusqu'en haut
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',  // Animation fluide
    });
  };

  // Contrôler la visibilité du bouton en fonction du défilement de l'utilisateur
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {  // Si l'utilisateur a scrollé plus de 300px
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    // Nettoyage lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Gérer les événements de la souris
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsClicked(false);
  };

  const handleMouseDown = () => {
    setIsClicked(true);
  };

  const handleMouseUp = () => {
    setIsClicked(false);
  };

  return (
    <>
      {isVisible && (
        <div
          className={`scroll-to-top ${isHovered ? 'hovered' : ''} ${isClicked ? 'clicked' : ''}`}
          onClick={scrollToTop}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <ArrowUpwardIcon
            className={`scroll-arrow ${isHovered ? 'hovered' : ''} ${isClicked ? 'clicked' : ''}`}
            style={{ fontSize: 24 }}
          />
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;

