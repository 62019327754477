import { Helmet } from "react-helmet";

import "./Cgu.css";

function Cgu() {
  return (
    <div className="cgu-contenaire">
      <Helmet>
        <title>Conditions générales d'utilisation</title>
        <meta name="description" content="CGU" />
        <meta
          name="keywords"
          content="conditions générales d'utilisation, coode-nt, développement d'applications, web, mobiles, cgu"
        />
      </Helmet>
      <div className="div-1">
        <h1>Conditions générales d'utilisation</h1>
        <p class="date">Date de mise à jour : Lundi 18 novembre 2024</p>
        <p>
          Les présentes Conditions Générales d'Utilisation (CGU) régissent
          l'accès et l'utilisation du site internet COODE-NT (ci-après « le Site
          »), édité par COODE-NT, spécialisé dans le développement
          d'applications web et mobiles pour professionnels et particuliers. En
          accédant au Site, l'utilisateur accepte sans réserve les présentes
          CGU.
        </p>

        <h2>1. Présentation et Objet</h2>
        <p>
          Les présentes CGU ont pour objet de définir les conditions dans
          lesquelles les utilisateurs peuvent accéder au Site et l'utiliser. Le
          Site fournit des informations sur les services proposés par COODE-NT
          et peut permettre l'accès à des espaces de candidature pour des offres
          d'emploi.
        </p>

        <h2>2. Propriété Intellectuelle</h2>
        <p>
          Le nom COODE-NT est une marque protégée. Tous les éléments composant
          le Site (structure, design, contenu, textes, graphiques, logos,
          images, vidéos, etc.) sont la propriété exclusive de COODE-NT ou de
          tiers ayant autorisé son utilisation. Toute reproduction,
          représentation, modification ou adaptation de tout ou partie des
          éléments du Site, par quelque moyen que ce soit, est interdite sans
          autorisation écrite préalable de COODE-NT et constitue une contrefaçon
          sanctionnée par le Code de la propriété intellectuelle.
        </p>

        <h2>3. Utilisation du Site et Responsabilités de l'Utilisateur</h2>
        <p>
          L'utilisateur s'engage à utiliser le Site de manière conforme à la loi
          et aux présentes CGU. Il lui est strictement interdit de :
        </p>
        <ul>
          <li>Perturber, interférer ou interrompre l'accès au Site.</li>
          <li>
            Utiliser des moyens automatisés pour extraire ou collecter des
            données sans autorisation.
          </li>
          <li>
            Publier, diffuser ou télécharger du contenu illégal ou portant
            atteinte aux droits de tiers.
          </li>
        </ul>
        <p>
          COODE-NT décline toute responsabilité en cas d'utilisation du Site non
          conforme aux présentes CGU.
        </p>

        <h2>4. Inscription et Compte Utilisateur</h2>
        <p>
          L'accès à certaines parties du Site peut nécessiter la création d'un
          compte utilisateur. L'utilisateur est responsable de la
          confidentialité de ses identifiants et s'engage à ne pas les
          divulguer. COODE-NT ne saurait être tenu responsable de toute
          utilisation non autorisée du compte. En cas de perte ou d'utilisation
          frauduleuse, l'utilisateur doit en informer COODE-NT sans délai.
        </p>

        <h2>5. Candidatures aux Offres d'Emploi</h2>
        <p>
          Le Site peut comporter une section dédiée aux offres d'emploi
          proposées par COODE-NT. Les utilisateurs intéressés peuvent soumettre
          leur candidature via le formulaire dédié ou par tout autre moyen
          spécifié dans l'annonce. En soumettant une candidature, l'utilisateur
          autorise COODE-NT à traiter les informations personnelles fournies
          dans le cadre du processus de recrutement, conformément à sa Politique
          de Confidentialité. COODE-NT s'engage à traiter les candidatures de
          manière confidentielle et n'a pas l'obligation de justifier une
          décision prise dans le cadre du processus de recrutement. L'envoi
          d'une candidature ne garantit pas un retour ou un embauche. COODE-NT
          se réserve le droit de retirer une offre d'emploi à tout moment sans
          préavis.
        </p>
        <h2>6. Protection des Données Personnelles</h2>
        <p>
          COODE-NT s'engage à protéger les données personnelles des utilisateurs
          conformément à la réglementation en vigueur. Les informations
          collectées sont traitées conformément à la Politique de
          Confidentialité, disponible sur le Site. L'utilisateur dispose d'un
          droit d'accès, de rectification, et de suppression de ses données
          personnelles, qu'il peut exercer en contactant COODE-NT à l'adresse
          suivante : contact@coode-nt.com.
        </p>

        <h2>7. Cookies</h2>
        <p>
          Le Site peut utiliser des cookies pour améliorer l'expérience
          utilisateur et pour des statistiques de visites. En naviguant sur le
          Site, l'utilisateur consent à l'utilisation de cookies. Il a la
          possibilité de les désactiver via les paramètres de son navigateur,
          bien que cela puisse restreindre certaines fonctionnalités du Site.
        </p>

        <h2>8. Limitation de Responsabilité</h2>
        <p>
          COODE-NT s'efforce de maintenir le Site accessible et à jour, mais ne
          garantit pas l'exactitude ou la complétude des informations
          présentées. COODE-NT ne pourra être tenu responsable des dommages
          directs ou indirects résultant de l'utilisation du Site, notamment en
          cas de bugs, interruptions, ou pertes de données.
        </p>

        <h2>9. Liens Hypertextes</h2>
        <p>
          Le Site peut contenir des liens vers des sites tiers. COODE-NT
          n'exerce aucun contrôle sur ces sites et décline toute responsabilité
          quant à leur contenu ou aux services proposés. L'insertion de ces
          liens n'implique pas une approbation des contenus desdits sites.
        </p>

        <h2>10. Modification des CGU</h2>
        <p>
          COODE-NT se réserve le droit de modifier les présentes CGU à tout
          moment pour les adapter aux évolutions du Site, de la réglementation
          ou des pratiques commerciales. Les utilisateurs sont invités à
          consulter régulièrement les CGU pour prendre connaissance des
          modifications.
        </p>

        <h2>11. Médiation en cas de Litige</h2>
        <p>
          En cas de litige, l'utilisateur peut avoir recours gratuitement à un
          médiateur de la consommation en vue de la résolution amiable du
          différend avec COODE-NT, conformément à l'article L612-1 du Code de la
          consommation. Les informations concernant le médiateur seront
          communiquées sur simple demande de l'utilisateur, adressée par email à
          contact@coode-nt.com. À défaut de solution amiable, les tribunaux
          compétents seront saisis.
        </p>

        <h2>12. Droit Applicable et Compétence</h2>
        <p>
          Les présentes CGU sont régies par le droit français. En cas de litige
          et après échec de la médiation, les tribunaux compétents de [ex. :
          Paris] seront seuls compétents.
        </p>

        <h2>13. Contact et Support Technique</h2>
        <p>
          Pour toute question relative aux présentes CGU, à la propriété
          intellectuelle, ou pour signaler un contenu inapproprié, l'utilisateur
          peut contacter COODE-NT à l'adresse suivante : contact@coode-nt.com.
        </p>
        <h2>14. Acceptation des CGU</h2>
        <p>
          L’utilisation du Site implique l’acceptation pleine et entière des
          présentes CGU par l’utilisateur. En cas de non-respect des CGU,
          COODE-NT se réserve le droit de suspendre ou de supprimer l'accès au
          Site sans préavis.
        </p>
      </div>
    </div>
  );
}

export default Cgu;
