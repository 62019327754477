import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  teamMemberAddAction,
  teamMemberDeleteAction,
  teamMemberListAction,
  teamMemberUpdateAction,
  updateOrderAction,
} from "../../../redux/actions/teamMembersActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Propos.css";

function AdminPropos() {
  const dispatch = useDispatch();

  // Affichage de la section videos
  const [showVideoSection, setShowVideoSection] = useState(true); // Nouvel état pour la section vidéo

  const handleToggleVideoSection = () => {
    setShowVideoSection(!showVideoSection); // Inverse l'état
  };

  // Récupérer les membres depuis le store Redux
  const teamMemberList = useSelector((state) => state.teamMemberList);
  const { teamMembers, loading, error } = teamMemberList;

  const [newMember, setNewMember] = useState({
    name: "",
    title: "",
    image: "",
    isActive: true,
  });
  const [editingMember, setEditingMember] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [members, setMembers] = useState([]);

  // Ajout d'une référence pour le formulaire
  const formRef = useRef(null);

  useEffect(() => {
    if (teamMembers) {
      setMembers(teamMembers);
    }
  }, [teamMembers]);

  useEffect(() => {
    dispatch(teamMemberListAction());
  }, [dispatch]);

  useEffect(() => {
    if (teamMembers) {
      // Mettre à jour members et définir isActive à true si pas défini
      const updatedMembers = teamMembers.map((member) => ({
        ...member,
        isActive: member.is_active,
      }));
      setMembers(updatedMembers);
    }
  }, [teamMembers]);

  // Gestion des ajouts et modifications
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember({ ...newMember, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    // Permet d'obtenir la chaîne base64 de l'image
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      if (editingMember) {
        setEditingMember({ ...editingMember, image: base64String });
      } else {
        setNewMember({ ...newMember, image: base64String });
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Fonction d'Ajout d'un Member
  const handleAddMember = () => {
    const memberData = {
      name: newMember.name,
      title: newMember.title,
      image: newMember.image,
      order: members.length,
      isActive: newMember.isActive,
    };

    dispatch(teamMemberAddAction(memberData));
    setNewMember({ name: "", title: "", image: "", isActive: true });
    setShowForm(false);
  };

  // Fonction de mise à jour d'un Member
  const handleUpdateMember = () => {
    const memberData = {
      name: editingMember.name,
      title: editingMember.title,
      image:
        editingMember.image ||
        members.find((member) => member.id === editingMember.id).image_url,
      order: editingMember.order,
      is_active: editingMember.isActive,
    };
    // Envoie la mise à jour
    dispatch(teamMemberUpdateAction(editingMember.id, memberData)).then(
      (response) => {}
    );
    // Réinitialiser l'état d'édition
    setEditingMember(null);
    setShowForm(false);
  };

  const handleDeleteMember = (id) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce membre ?"
    );
    if (confirmed) {
      dispatch(teamMemberDeleteAction(id));
    }
  };

  // Gestion du drag and drop
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(members);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Mettre à jour l'ordre des membres
    const updatedMembers = items.map((member, index) => ({
      ...member,
      order: index,
    }));
    setMembers(updatedMembers);
    // Appel à l'API pour mettre à jour l'ordre
    dispatch(updateOrderAction(updatedMembers));
  };

  const handleEditClick = (member) => {
    setEditingMember(member);
    setNewMember({
      name: member.name,
      title: member.title,
      image: member.image_url,
      isActive: member.isActive,
    });
    setShowForm(true);

    // Ajout d'un délai avant de faire défiler
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Délai de 100 ms
  };

  function normalizeTitle(title) {
    return title
      .toLowerCase()
      .normalize("NFD") // Pour supprimer les accents
      .replace(/[\u0300-\u036f]/g, "") // Supprimer les accents restants
      .replace(/é/g, "e") // Remplacement explicite des caractères problématiques
      .replace(/[\s\/]+/g, "-") // Remplacer espaces et slashs par un seul tiret
      .replace(/-+/g, "-") // Remplacer les tirets multiples par un seul
      .replace(/^-+|-+$/g, ""); // Supprimer les tirets en début ou fin de chaîne
  }

  function getRoleClass(title) {
    const normalizedTitle = normalizeTitle(title);

    switch (normalizedTitle) {
      case "lead-developpeur":
      case "developpeuse":
      case "developpeur":
        return "role-developpeur";
      case "directrice-generale-deleguee":
      case "directrice-generale":
      case "president-directeur-general":
      case "assistante-de-direction":
        return "role-direction";
      case "ux-ui-designer":
      case "designer":
        return "role-ux-ui-designer";
      default:
        return "";
    }
  }

  return (
    <div className="admin-team-management">
      <h2>Gestion des membres de l'équipe</h2>

      {loading && <p>Chargement des membres...</p>}
      {error && <p>Erreur lors du chargement : {error}</p>}

      {/* Liste des membres avec Drag and Drop */}
      <div>
        <h3>Membres actuels</h3>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="members">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {members && members.length > 0 ? (
                  members.map((member, index) => (
                    <Draggable
                      key={member.id}
                      draggableId={member.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className={`member-item ${getRoleClass(
                            member.title
                          )} ${member.isActive ? "active-member" : ""}`}
                          style={{
                            ...provided.draggableProps.style,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "10px",
                            border: member.isActive
                              ? "3px solid #170e91"
                              : "none",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {member.image_url ? (
                              <img
                                src={`data:image/jpeg;base64,${member.image_url}`}
                                alt={member.name}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                  backgroundColor: "#fff",
                                  marginLeft: "10px",
                                }}
                              />
                            ) : (
                              <p style={{ margin: "10px", color: "gray" }}>
                                Pas d'image
                              </p>
                            )}
                            <div>
                              <p style={{ margin: 0 }}>{member.name}</p>
                              <p style={{ margin: 0 }}>{member.title}</p>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {/* Afficher le statut "Actif" ou "Inactif" */}
                            <span
                              style={{
                                color: member.isActive ? "green" : "gray",
                                fontWeight: "bold",
                                marginRight: "8px",
                              }}
                            >
                              {member.isActive ? "Actif" : "Inactif"}
                            </span>
                            {/* Icône de modification */}
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={() => handleEditClick(member)}
                              style={{ cursor: "pointer", marginRight: "8px" }}
                            />
                            {/* Icône de suppression */}
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => handleDeleteMember(member.id)}
                              style={{ cursor: "pointer", color: "red" }}
                            />
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p>Aucun membre trouvé.</p>
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {/* Bouton "+" pour afficher ou masquer le formulaire */}
      <div className="add-member-button">
        <button
          onClick={() => {
            setShowForm(!showForm);
            setEditingMember(null);
          }}
        >
          <FontAwesomeIcon icon={faPlus} size="2x" />
        </button>
      </div>

      {/* Formulaire d'ajout ou de modification */}
      {showForm && (
        <div ref={formRef} style={{ marginTop: "20px" }}>
          <h3>
            {editingMember ? "Modifier un membre" : "Ajouter un nouveau membre"}
          </h3>
          <form style={{ marginRight: "16px" }}>
            <input
              type="text"
              name="name"
              value={editingMember ? editingMember.name : newMember.name}
              onChange={
                editingMember
                  ? (e) =>
                      setEditingMember({
                        ...editingMember,
                        name: e.target.value,
                      })
                  : handleInputChange
              }
              placeholder="Nom"
            />
            <input
              type="text"
              name="title"
              value={editingMember ? editingMember.title : newMember.title}
              onChange={
                editingMember
                  ? (e) =>
                      setEditingMember({
                        ...editingMember,
                        title: e.target.value,
                      })
                  : handleInputChange
              }
              placeholder="Titre"
            />
            <input type="file" name="image" onChange={handleImageUpload} />
            <select
              value={
                editingMember
                  ? editingMember.isActive
                    ? "true"
                    : "false"
                  : "true"
              }
              onChange={(e) =>
                setEditingMember({
                  ...editingMember,
                  isActive: e.target.value === "true",
                })
              }
              style={{ marginLeft: "10px" }}
            >
              <option value="true">Actif</option>
              <option value="false">Inactif</option>
            </select>
            <button
              style={{ marginLeft: "24px" }}
              type="button"
              onClick={editingMember ? handleUpdateMember : handleAddMember}
            >
              {editingMember ? "Mettre à jour" : "Ajouter"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default AdminPropos;
