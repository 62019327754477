import React from 'react';
import mdi from '../../assets/mdi-light.svg'; // Assurez-vous que mdi est correctement importé.
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '../Button/LinkButton/LinkButton';

const JobCard = ({ title, position, location, skills, skills_short, id, extra_skills, missions, why_join, benefits_join }) => {
  const navigate = useNavigate();

  const jobDetails = {
    title: title,
    position: position,
    location: location,
    skills: skills,
    skills_short: skills_short,
    id: id,
    extra_skills: extra_skills,
    missions: missions,
    why_join: why_join,
    benefits_join: benefits_join
  };

  const handleApplyClick = (value) => {
    if (value === "Postuler") {
      navigate("/candidature", { state: jobDetails });
    } else if (value === "En savoir plus") {
      navigate("/offres-detail", { state: jobDetails });
    }
  };

  const handleTruncateSkills = (skills) => {
    let truncatedSkills = skills
    if (skills.length > 230) {
      const result = skills.substring(0, 225);
      truncatedSkills = result.concat('...')
    }
    return truncatedSkills
  }

  return (
    <div className="auto-layout-vertical-3">
      <div className="auto-layout-vertical-4">
        <div className="text-wrapper-7">{title}</div>
        <div className="text-wrapper-8">{position}</div>
        <div className="frame-3">
          <img className="mdi-location" alt="Mdi location" src={mdi} />
          <p className="p">{location}</p>
        </div>
        <p className="text-wrapper-9">
          {skills_short && handleTruncateSkills(skills_short)}
        </p>
        {skills_short && skills_short.length > 230 && <a style={{ textDecoration: 'underline' }} onClick={() => handleApplyClick("En savoir plus")}>voir plus</a>}
      </div>
      <div className="auto-layout-2">
        <span className="pointer-span" onClick={() => handleApplyClick("En savoir plus")}>
          <LinkButton
            className="design-component-instance-node-2"
            style="outlined"
            text="En savoir plus"
          />
        </span>
        <span className="pointer-span" onClick={() => handleApplyClick("Postuler")}>
          <LinkButton
            className="design-component-instance-node-2"
            style="filled"
            text="Postuler" />
        </span>
      </div>
    </div>
  );
};

export default JobCard;
