import { combineReducers } from 'redux';

import {
    contactListReducer,
    contactAddReducer,
    contactDeleteReducer,
    contactUpdateReducer,
    objetContactListReducer,
    objetContactAddReducer,
    objetContactUpdateReducer,
    objetContactDeleteReducer,
} from './contactReducers';

import {
    informationListReducer,
    informationAddReducer,
    informationDeleteReducer,
    informationUpdateReducer
} from './informationReducers';

import {
    partenaireListReducer,
    partenaireAddReducer,
    partenaireDeleteReducer,
    partenaireUpdateReducer
} from './partenaireReducers';

import {
    candidatureListReducer,
    candidatureAddReducer,
    candidatureDeleteReducer,
    candidatureUpdateReducer
} from './candidatureReducers';

import {
    candidatureEmploiListReducer,
    candidatureEmploiAddReducer,
    candidatureEmploiDeleteReducer,
    candidatureEmploiUpdateReducer
} from './candidatureEmploiReducers';

import {
    offreEmploiListReducer,
    offreEmploiAddReducer,
    offreEmploiDeleteReducer,
    offreEmploiUpdateReducer
} from './offreEmploiReducers';

import {
    projetListReducer,
    projetAddReducer,
    projetDeleteReducer,
    projetUpdateReducer
} from './projetReducers';

import {
    teamMemberListReducer,
    teamMemberAddReducer,
    teamMemberDeleteReducer,
    teamMemberUpdateReducer
} from './teamMemberReducers';

import userReducer from '../../features/user/userSlice';
import projetSlice from "../Slices/projetSlice";
import etreAvertiSlice from "../Slices/etreAvertiSlice"

import {
    jobOfferAddReducer,
    jobOfferDeleteReducer,
    jobOfferListReducer,
    jobOfferUpdateReducer
} from './jobOfferReducers';

import {
    disposableEmailAddReducer,
    disposableEmailDeleteReducer,
    disposableEmailListReducer,
    disposableEmailUpdateReducer
} from './emailJetableReducers';

import {
    parametresListReducer,
    parametresAddReducer,
    parametresDeleteReducer,
    parametresUpdateReducer
} from './parametresReducers';

import {
    videoListReducer
} from './videoReducers';

const appReducer = combineReducers({
    contactList: contactListReducer,
    contactAdd: contactAddReducer,
    contactDelete: contactDeleteReducer,
    contactUpdate: contactUpdateReducer,

    objetContactList: objetContactListReducer,
    objetContactAdd: objetContactAddReducer,
    objetContactUpdate: objetContactUpdateReducer,
    objetContactDelete: objetContactDeleteReducer,

    disposableEmailList: disposableEmailListReducer,
    disposableEmailAdd: disposableEmailAddReducer,
    disposableEmailDelete: disposableEmailDeleteReducer,
    disposableEmailUpdate: disposableEmailUpdateReducer,

    informationList: informationListReducer,
    informationAdd: informationAddReducer,
    informationDelete: informationDeleteReducer,
    informationUpdate: informationUpdateReducer,

    partenaireList: partenaireListReducer,
    partenaireAdd: partenaireAddReducer,
    partenaireDelete: partenaireDeleteReducer,
    partenaireUpdate: partenaireUpdateReducer,

    candidatureList: candidatureListReducer,
    candidatureAdd: candidatureAddReducer,
    candidatureDelete: candidatureDeleteReducer,
    candidatureUpdate: candidatureUpdateReducer,

    candidatureEmploiList: candidatureEmploiListReducer,
    candidatureEmploiAdd: candidatureEmploiAddReducer,
    candidatureEmploiDelete: candidatureEmploiDeleteReducer,
    candidatureEmploiUpdate: candidatureEmploiUpdateReducer,

    offreEmploiList: offreEmploiListReducer,
    offreEmploiAdd: offreEmploiAddReducer,
    offreEmploiDelete: offreEmploiDeleteReducer,
    offreEmploiUpdate: offreEmploiUpdateReducer,

    projetList: projetListReducer,
    projetAdd: projetAddReducer,
    projetDelete: projetDeleteReducer,
    projetUpdate: projetUpdateReducer,

    teamMemberList: teamMemberListReducer,
    teamMemberAdd: teamMemberAddReducer,
    teamMemberDelete: teamMemberDeleteReducer,
    teamMemberUpdate: teamMemberUpdateReducer,

    jobOfferList: jobOfferListReducer,
    jobOfferAdd: jobOfferAddReducer,
    jobOfferDelete: jobOfferDeleteReducer,
    jobOfferUpdate: jobOfferUpdateReducer,

    parametresList: parametresListReducer,
    parametresAdd: parametresAddReducer,
    parametresDelete: parametresDeleteReducer,
    parametresUpdate: parametresUpdateReducer,

    projets: projetSlice,
    etreAverti: etreAvertiSlice,

    userLogin: userReducer,

    videoList: videoListReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }
    return appReducer(state, action);
};