import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LinkButton } from "../../components/Button/LinkButton/LinkButton";
import "./style.css";

export const OffreEmploiDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    title,
    skills,
    position,
    extra_skills,
    missions,
    why_join,
    benefits_join,
  } = location.state || {};

  const handleApplyClick = () => {
    navigate("/candidature", { state: location.state });
  };

  return (
    <div className="fiche-offre-emploi">
      <Helmet>
        <title>Détails d'offres d'emplois</title>
        <meta name="description" content="Détails offres d'emplois" />
        <meta
          name="keywords"
          content="missions, coode-nt, rejoindre, ce que nous recherchons, candidature, avantages, postuler, offres d'emplois"
        />
      </Helmet>
      <div className="content-section">
        <div className="auto-layout-vertical">
          <p className="accueil-nous">
            <span className="span">
              <Link className="no-decoration span" to="/">
                Accueil
              </Link>
              &nbsp;&gt;&nbsp;
              <Link className="no-decoration span" to="/rejoindre">
                Nous Rejoindre
              </Link>{" "}
              &gt;
            </span>
            <span className="text-wrapper-5"> {position}</span>
          </p>
          <div className="frame-2">
            <div className="text-wrapper-6">{title}</div>
            <div className="text-wrapper-7">{position}</div>
          </div>
          <div className="separation-line margin-zero" />
        </div>

        {missions && (
          <>
            <div className="offre-titres">Tes missions 💻</div>
            <div dangerouslySetInnerHTML={{ __html: missions }} />
            {skills && <div className="separation-line margin-zero" />}
          </>
        )}

        {skills && (
          <>
            <div className="offre-titres">Ce que nous recherchons 🔍</div>
            <div dangerouslySetInnerHTML={{ __html: skills }} />
            {extra_skills && <div className="separation-line margin-zero" />}
          </>
        )}

        {extra_skills && (
          <>
            <div className="offre-titres">
              Et dans nos aspirations les plus audacieuses 🤩
            </div>
            <div dangerouslySetInnerHTML={{ __html: extra_skills }} />
            {why_join && <div className="separation-line margin-zero" />}
          </>
        )}

        {why_join && (
          <>
            <div className="offre-titres">Pourquoi nous rejoindre 😗✌</div>
            <div dangerouslySetInnerHTML={{ __html: why_join }} />
            {skills && <div className="separation-line margin-zero" />}
          </>
        )}

        {benefits_join && (
          <>
            <div className="offre-titres">Les avantages 🏅</div>
            <div dangerouslySetInnerHTML={{ __html: benefits_join }} />
          </>
        )}
      </div>
      <span className="button-offre">
        <span className="pointer-span" onClick={handleApplyClick}>
          <LinkButton className="button-3" style="filled" text="Postuler" />
        </span>
      </span>
    </div>
  );
};
